<template>
	<div class="analytics content-padding">
		<ContentContainer :order="{ body: 1, sidebar: 2 }">

			<template slot="body">

				<BaseCard class="p-0 p-sm-2 p-md-4" margin="0 0 25px">
					<LineChart/>
				</BaseCard>

				<p>{{labels.some_text}}</p>

				<LabelButtonCard :list="downloadList" class="mb-4"/>

				<p>{{labels.some_text}}</p>

			</template>

			<template slot="sidebar">

				<LabelButtonCard :list="copyList" class="mb-4"/>

				<TitleButtonCard
					class="mb-4"
					:title="related_burder.title"
					:button="related_burder.button"
				/>

				<CardGenerateEntity
					class="mb-4"
					:title="createReceipt.title"
					:drop-down="createReceipt.dropdown"
					:button="createReceipt.button"
					@changed="createReceiptChanged"
				/>

				<LabelButtonCard :list="editList" class="mb-4"/>

			</template>

		</ContentContainer>
	</div>
</template>

<script>
import ContentContainer from "@/components/Containers/ContentContainer"
import LineChart from "@/components/Chart/LineChart"
import LabelButtonCard from '@/components/Cards/LabelButtonCard'
import TitleButtonCard from "@/components/Cards/TitleButtonCard"
import CardGenerateEntity from "@/components/Cards/CardGenerateEntity"
import BaseCard from "@/components/Cards/BaseCard"

export default {
	name: 'Analytics',
	components: {
		ContentContainer,
		LineChart,
		LabelButtonCard,
		TitleButtonCard,
		CardGenerateEntity,
		BaseCard,
	},
	data() {
		return {
			labels: {
				some_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam rutrum molestie nulla eget tristique. Praesent porta at urna Generate sit amet fringilla",
			},
			related_burder: {
				title: "Related monetavizator burder",
				button: {label: 'Select', variant:'btn-green', action: () => {}}
			},
			createReceipt: {
				title: 'Create receipt',
				button: { label: 'Generate', variant: 'btn-green' },
				dropdown: {
					placeholder: 'Select entity',
					list: [
						{ value: 1, label: 'Month' },
						{ value: 2, label: 'Week' },
					],
				},
			},
			downloadList: [
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Month',
					actions: [
						{ type: 'button', label: 'Download', variant: 'btn-navy', action:()=>{} },
					],
				},
			],
			copyList: [
				{
					preTitle: "Link: ",
					label: 'Lorem ipsum',
					actions: [
						{ type: 'button', label: 'Copy', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					preTitle: "For: ",
					label: 'Lorem ipsum',
					actions: [
						{ type: 'button', label: 'Copy', variant: 'btn-navy', action:()=>{} },
					],
				},
			],
			editList: [
				{
					label: 'Legner',
					actions: [
						{ type: 'button', label: 'Edit', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Legner',
					actions: [
						{ type: 'button', label: 'Edit', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Legner',
					actions: [
						{ type: 'button', label: 'Edit', variant: 'btn-navy', action:()=>{} },
					],
				},
				{
					label: 'Legner',
					actions: [
						{ type: 'button', label: 'Edit', variant: 'btn-navy', action:()=>{} },
					],
				},
			],
		}
	},
	methods: {
		createReceiptChanged( value ) {
			console.log(value);
		}
	}
}
</script>

<style lang='scss' scoped>

</style>