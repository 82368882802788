<template>
	<BaseCard class="title-button-card" padding="0 25px 25px">
		<div class="title-button-card__title">
			{{ title }}
		</div>
		<Button
			class="title-button-card__button"
			:variant="button.variant"
			:label="button.label"
			:action="button.action"
		/>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from '@/components/Buttons/Button.vue';

export default {
	name: 'TitleButtonCard',
	components: {
		BaseCard,
		Button,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		button: {
			type: Object,
			default: () => {}
		},
	},
}
</script>

<style lang='scss' scoped>

.title-button-card {

	&__title {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		height: 60px;
		line-height: 60px;
		text-align: center;
	}

	&__button {
		display: block;
		margin: 0 auto;
		padding: 7px 20px;
	}
}

</style>