<template>
	<BaseCard class="generate-entity" padding="0 25px 25px">
		<div class="generate-entity__title">
			{{ title }}
		</div>
		<Dropdown
			class="generate-entity__dropdown"
			:placeholder="dropDown.placeholder"
			:list="dropDown.list"
			@changed="dropDownChanged"
		/>
		<Button
			class="generate-entity__button"
			:variant="button.variant"
			:label="button.label"
			:action="button.action"
		/>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Dropdown from "@/components/Dropdowns/Dropdown";
import Button from '@/components/Buttons/Button.vue';

export default {
	name: 'CardGenerateEntity',
	components: {
		BaseCard,
		Dropdown,
		Button,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		dropDown: {
			type: Object,
			default: () => {}
		},
		button: {
			type: Object,
			default: () => {}
		},
	},
	methods: {
		dropDownChanged( value ) {
			this.$emit( 'changed', value )
		}
	}
}
</script>

<style lang='scss' scoped>

.generate-entity {

	&__title {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		height: 60px;
		line-height: 60px;
		text-align: center;
	}

	&__dropdown {
		margin-bottom: 20px;
	}

	&__button {
		display: block;
		margin: 0 auto;
		padding: 7px 20px;
	}
}

</style>